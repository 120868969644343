import { PageElement, html, css } from 'Elements';

import './registry';
import './system';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }

  renderSettings() {
    return html`
      <section-header size="medium" micon="settings">Configuration</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="diversity_3" aroute="/private/admin/registry">Base de registre</box-styled>
        <box-styled layout="${this.layout}" micon="article" aroute="/private/admin/sys/templates">Modèles email/SMS</box-styled>
        <box-styled layout="${this.layout}" micon="globe_asia" aroute="/private/admin/sys/languages">Internationalisation</box-styled>
        <box-styled layout="${this.layout}" micon="event" aroute="/private/admin/sys/holidays">Jours fériés</box-styled>
        <box-styled layout="${this.layout}" micon="responsive_layout" aroute="/private/admin/sys/naf">Codes NAF/APE</box-styled>
      </div>
      <br/>
    `
  }

  renderTools() {
    return html`
      <section-header size="medium" micon="construction">Outils</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="calendar_clock" aroute="/private/admin/sys/events">Evènements</box-styled>
        <box-styled layout="${this.layout}" micon="dvr" aroute="/private/admin/sys/logs">Logs</box-styled>
        <box-styled layout="${this.layout}" micon="bring_your_own_ip" aroute="/private/admin/sys/sessions">Sessions en cours</box-styled>
        <!-- <box-styled layout="${this.layout}" micon="notifications" aroute="/private/admin/sys/notifications">Notifications</box-styled> -->
        <box-styled layout="${this.layout}" micon="settings_backup_restore" aroute="/private/admin/sys/backup_restore">Sauvegarde et restauration</box-styled>
        <box-styled layout="${this.layout}" micon="avg_time" aroute="/private/admin/sys/monitor">Santé du serveur</box-styled>
        <box-styled layout="${this.layout}" micon="database" aroute="/private/admin/sys/mongodb">MongoDB</box-styled>
        <box-styled layout="${this.layout}" micon="database" aroute="/private/admin/sys/opensearch">Opensearch</box-styled>
        <box-styled layout="${this.layout}" micon="database" aroute="/private/admin/sys/redis">Redis</box-styled>
        
      </div>
      <br/>
    `
  }
  render() {
    return html`
        <section-header micon="admin_panel_settings" backroute="../">Administration</section-header>
        <br/>
        ${this.renderSettings()}
        ${this.renderTools()}
        <br/><br/>
      </div>
    `;
  }

}

customElements.define('page-admin-home', Page);